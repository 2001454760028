import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';

export const PinIcon = ({ isPinned, ...props }) => (
  <svg
    focusable="false"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="16"
    viewBox="0 0 10 16"
    style={{
      fillRule: 'evenodd',
    }}
    className={classNames({
      'forum-secondary-fill-color': isPinned,
    })}
    {...props}
  >
    <path
      d="M1003,212h-6a2.18,2.18,0,0,0-2,2v13a1,1,0,0,0,1,1l4-3.465,4,3.465a1.016,1.016,0,0,0,1-1V214A1.976,1.976,0,0,0,1003,212Z"
      transform="translate(-995 -212)"
    />
  </svg>
);

PinIcon.propTypes = {
  isPinned: PropTypes.bool,
};
