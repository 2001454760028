let isSupported;
export function isWebpSupported() {
  if (isSupported !== undefined) {
    return isSupported;
  }
  try {
    const canvas = typeof document === 'object' ? window.document.createElement('canvas') : {};
    canvas.width = canvas.height = 1;
    isSupported = canvas.toDataURL
      ? canvas.toDataURL('image/webp').indexOf('image/webp') === 5
      : false;
  } catch (e) {
    isSupported = false;
  } finally {
    return isSupported;
  }
}
