import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import { ImgBackground } from '@wix/communities-image-lib';
import { forceImageHttps } from '../../services/force-image-https';
import styles from './fluid-avatar-image.scss';

const FluidAvatarImage = ({ image }) => (
  <ImgBackground
    src={forceImageHttps(image)}
    width={100}
    height={100}
    className={classNames(styles.container, 'fluid-avatar-image')}
    aria-hidden="true"
  />
);

FluidAvatarImage.propTypes = {
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

export default FluidAvatarImage;
