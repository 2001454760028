import React from 'react';

function createLoadedComponentsCache() {
  const loadedComponent = [];

  return {
    get: loadComponent => {
      const hit = loadedComponent.find(([f]) => f === loadComponent);
      if (hit) {
        return hit[1];
      } else {
        return null;
      }
    },

    set: (loadComponent, comp) => loadedComponent.push([loadComponent, comp]),
  };
}

function loadWithRetry(loadComponent, retries = 3) {
  return loadComponent().catch(e => {
    if (retries > 0) {
      console.warn('loadComponent failed; reties left', retries);
      return loadWithRetry(loadComponent, retries - 1);
    } else {
      return Promise.reject(e);
    }
  });
}

const loadedComponents = createLoadedComponentsCache();

export function loadable(
  loadComponent,
  { loading = () => null, resolveComponent = module => module.default } = {},
) {
  let loadComponentPromise;
  class Loadable extends React.Component {
    state = {
      Component: null,
    };

    static load() {
      if (!loadComponentPromise) {
        loadComponentPromise = loadWithRetry(loadComponent);
      }

      return loadComponentPromise;
    }

    static getDerivedStateFromProps() {
      const comp = loadedComponents.get(loadComponent);
      if (comp) {
        return { Component: comp };
      } else {
        return null;
      }
    }

    componentDidMount() {
      if (!this.state.Component) {
        Loadable.load()
          .then(module => {
            const comp = resolveComponent(module);
            loadedComponents.set(loadComponent, comp);
            this.setState({ Component: comp });
          })
          .catch(err => console.error('Failed to load async component', err));
      }
    }

    render() {
      const { Component } = this.state;
      const { loadableLoading } = this.props;
      const showLoader = loadableLoading || loading;
      return Component ? <Component {...this.props} /> : showLoader(this.props);
    }
  }

  return Loadable;
}
