//TODO: this needs to read from the settings in the DB. this will ensure that the forum will be out of wix like it is in wix
export const getMainColor = () => {
  //TODO:OOI fix this hardcoded stuff
  return '#27a456';
};

export const getSecondaryColor = () => {
  return '#faad4d';
};

export const getSecondaryColorNumbers = () => {
  const color = getSecondaryColor();
  const matches = color.match(/#([\da-f]{2})([\da-f]{2})([\da-f]{2})/i);
  return matches.slice(1).map(number => parseInt(number, 16));
};

export const getDisabledColor = () => {
  return '#f0f0f0';
};
