import React from 'react';
import PropTypes from 'prop-types';
import { omit, get, noop } from 'lodash';
import classNames from 'classnames';
import { Badge as MembersBadge } from '@wix/members-badge-lib';
import { connect } from '../../../common/components/runtime-context';
import styles from './badge.scss';

const getStrippedBadge = (badge, textColor) => {
  const strippedBadge = omit(badge, 'backgroundColor');
  strippedBadge.textColor = textColor || badge.textColor;
  return strippedBadge;
};

const Badge = ({
  badge,
  hideBackground,
  size,
  textColor,
  truncateTitle = false,
  onClick = noop,
}) => {
  return (
    <div
      data-hook="members-badge"
      className={classNames(
        styles.root,
        hideBackground ? styles.stripped : '',
        truncateTitle ? styles.truncated : '',
      )}
    >
      <MembersBadge
        layout="name-icon"
        size={size}
        badge={hideBackground ? getStrippedBadge(badge, textColor) : badge}
        borderRadius={10}
        onClick={onClick}
        truncateTitle={truncateTitle}
        textStyle={{ fontWeight: 'inherit' }}
      />
    </div>
  );
};

Badge.propTypes = {
  onClick: PropTypes.func,
  badge: PropTypes.object.isRequired,
  hideBackground: PropTypes.bool,
  size: PropTypes.oneOf(['small', 'tiny']),
  textColor: PropTypes.string,
  truncateTitle: PropTypes.boolean,
};

const mapRuntimeToProps = (state, ownProps, actions, { style }) => ({
  textColor: get(style, ['styleParams', 'colors', 'post-textColor', 'value'], '#00000'),
});

export default connect(mapRuntimeToProps)(Badge);
