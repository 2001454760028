import PropTypes from 'prop-types';
import React from 'react';
import classNames from 'classnames';
import styles from './bordered-tooltip.scss';

const BorderedTooltip = ({ children, style, className, offset, placement }) => {
  style[placement] -= offset;
  return (
    <div
      className={classNames(styles.container, { [styles[placement]]: !!placement }, className)}
      style={style}
    >
      {children}
      <span className={styles.triangle} />
    </div>
  );
};

BorderedTooltip.propTypes = {
  children: PropTypes.node.isRequired,
  style: PropTypes.object,
  className: PropTypes.string,
  offset: PropTypes.number,
  placement: PropTypes.string,
};

BorderedTooltip.defaultProps = {
  placement: 'top',
  offset: 10,
};

export default BorderedTooltip;
