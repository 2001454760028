import React from 'react';
import PropTypes from 'prop-types';

export const SolvedIcon = ({ isColored, ...props }) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="19"
    height="19"
    viewBox="0 0 19 19"
    style={{
      fill: isColored && '#80C979',
    }}
    {...props}
  >
    <path
      fillRule="evenodd"
      d="M9.5,0 C14.7467051,0 19,4.25329488 19,9.5 C19,14.7467051 14.7467051,19 9.5,19 C4.25329488,19 0,14.7467051 0,9.5 C0,4.25329488 4.25329488,0 9.5,0 Z M14.7233564,6.80952595 C14.3420172,6.41002771 13.7090233,6.39530693 13.3095251,6.77664615 L8.02795975,11.5908676 L5.71862634,9.13759051 C5.33456902,8.74070461 4.70148945,8.73030499 4.30460355,9.11436231 C3.90771765,9.49841963 3.89731803,10.1314992 4.28137535,10.5283851 L7.28137535,13.695399 C7.66358023,14.0903706 8.29290528,14.1028571 8.69047659,13.7233572 L14.6904766,8.22335724 C15.0899748,7.84201801 15.1046956,7.20902418 14.7233564,6.80952595 Z"
    />
  </svg>
);

SolvedIcon.propTypes = {
  isColored: PropTypes.bool,
};
